import { SkeletonCircle } from "app/subframe/components/SkeletonCircle";
import { SkeletonText } from "app/subframe/components/SkeletonText";
import * as React from "react";

export interface GenericSkeletonProps {}

export const GenericSkeleton: React.FC<GenericSkeletonProps> = (props) => {
  const {} = props;
  return (
    <div className="flex relative h-[95dvh] my-2 grow shrink-0 basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:my-0 mobile:rounded-none mobile:h-[100dvh]">
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center justify-center gap-6 px-2 pt-10 mobile:w-[100vw] mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
        <div className="flex w-full items-center gap-6">
          <SkeletonCircle size="default" />
          <SkeletonText size="header" />
        </div>
      </div>
    </div>
  );
};
