"use client";
/*
 * Documentation:
 * Time chip — https://app.subframe.com/feb895689ba0/library?component=Time+chip_4e71c727-c02b-4163-ad9a-c05cbdf22ae9
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface TimeChipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  time?: React.ReactNode;
  selected?: boolean;
  className?: string;
}

const TimeChipRoot = React.forwardRef<HTMLElement, TimeChipRootProps>(
  function TimeChipRoot(
    { time, selected = false, className, ...otherProps }: TimeChipRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/4e71c727 flex cursor-pointer flex-col items-center gap-4 rounded-lg border border-solid border-neutral-100 bg-white px-4 py-4 hover:border hover:border-solid hover:border-neutral-300 hover:shadow-lg active:border active:border-solid active:border-neutral-900 active:bg-neutral-900",
          { "border border-solid border-neutral-900 bg-neutral-900": selected },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {time ? (
          <span
            className={SubframeCore.twClassNames(
              "w-full font-['Inter'] text-[18px] font-[500] leading-[24px] text-neutral-900 text-center group-active/4e71c727:text-white",
              { "text-white": selected }
            )}
          >
            {time}
          </span>
        ) : null}
      </div>
    );
  }
);

export const TimeChip = TimeChipRoot;
