import React from "react";
import "./App.css";
import { NextUIProvider } from "@nextui-org/react";
import { ToastContainer, Zoom } from "react-toastify";
import { useApp } from "./useApp";
import { Router } from "./routes/router";
import { AnimatePresence } from "framer-motion";
import "react-day-picker/style.css";

function App() {
  const { navigate } = useApp();
  return (
    <NextUIProvider navigate={navigate}>
      <AnimatePresence>
        <div className="flex w-full min-h-[100dvh] flex-col items-center gap-4 bg-millet-500 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:px-0 mobile:py-0 mobile:!bg-white">
          <Router />
        </div>
      </AnimatePresence>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        transition={Zoom}
        rtl={false}
        hideProgressBar={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </NextUIProvider>
  );
}

export default App;
