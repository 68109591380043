"use client";
/*
 * Documentation:
 * Location Card — https://app.subframe.com/feb895689ba0/library?component=Location+Card_9946e573-a9db-4c71-9c9f-ed26aef8c150
 * Icon Button — https://app.subframe.com/feb895689ba0/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { IconButton } from "./IconButton";

interface LocationCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: React.ReactNode;
  addressLine1?: React.ReactNode;
  addressLine2?: React.ReactNode;
  className?: string;
}

const LocationCardRoot = React.forwardRef<HTMLElement, LocationCardRootProps>(
  function LocationCardRoot(
    {
      name,
      addressLine1,
      addressLine2,
      className,
      ...otherProps
    }: LocationCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/9946e573 flex w-full cursor-pointer items-center gap-2 rounded-lg border border-solid border-neutral-50 bg-white px-4 py-4 hover:border hover:border-solid hover:border-neutral-300 hover:shadow-lg active:border active:border-solid active:border-neutral-700 active:bg-millet-300",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex grow shrink-0 basis-0 flex-col items-center gap-2">
          {name ? (
            <span className="w-full font-['Inter'] text-[18px] font-[500] leading-[28px] text-neutral-900">
              {name}
            </span>
          ) : null}
          <div className="flex w-full flex-col items-center gap-1">
            {addressLine1 ? (
              <span className="w-full font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                {addressLine1}
              </span>
            ) : null}
            {addressLine2 ? (
              <span className="w-full font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                {addressLine2}
              </span>
            ) : null}
          </div>
        </div>
        <IconButton
          disabled={false}
          variant="neutral-tertiary"
          size="small"
          icon="FeatherChevronRight"
          loading={false}
        />
      </div>
    );
  }
);

export const LocationCard = LocationCardRoot;
