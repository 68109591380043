import * as React from "react";
import { useManageBooking } from "./useManageBookng";
import { Header } from "app/subframe/components/Header";
import { Avatar } from "app/components/avatar/avatar";
import { LineItems } from "app/subframe/components/LineItems";
import * as SubframeCore from "@subframe/core";
import { AsButton } from "app/subframe/components/AsButton";
import { Loading } from "app/components/loading/loading";
import { AppointmentsSliceState } from "app/redux/appointmentsSlice";
import { getFormatedDuration } from "../bookService/useBookService";
import { format } from "date-fns";
import { Collapse } from "react-collapse";
import { motion } from "framer-motion";
import classNames from "classnames";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";

export interface ManageBookingProps {}

export const ManageBooking: React.FC<ManageBookingProps> = ({}) => {
  const {
    isTokenInvalid,
    loading,
    appointmentGroup,
    isAppointmentsExpanded,
    setIsAppointmentsExpanded,
    account,
    cancelAppointment,
    cancelingAppointments,
    onAppointmentReschedule,
  } = useManageBooking();

  if (loading) {
    return <Loading fullScreen type={"dark"} />;
  }

  // Render the first appointment and prepare additional appointments
  const appointments = appointmentGroup?.appointments || [];
  const firstAppointment = appointments?.[0];
  const additionalAppointments = appointments?.slice(1);

  return (
    <div className="flex h-[95dvh] my-4 pb-4 grow shrink-0 basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0">
      {" "}
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account?.logo}
        text={account?.name}
      />
      {!isTokenInvalid && appointmentGroup ? (
        <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
          <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
            {/* Render the first appointment */}
            <AppointmentDetails appointment={firstAppointment} />

            {/* Render the expand/collapse section if there are additional appointments */}
            {additionalAppointments.length > 0 && (
              <div
                className={classNames("flex flex-col relative w-full", {
                  "gap-4": isAppointmentsExpanded,
                })}
              >
                {/* Divider */}
                <div className="flex h-px w-full flex-none bg-neutral-100" />

                {/* Expandable section for additional appointments */}
                <Collapse
                  isOpened={isAppointmentsExpanded}
                  theme={{
                    collapse: "w-full",
                  }}
                >
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{
                      height: isAppointmentsExpanded ? "auto" : 0,
                      opacity: isAppointmentsExpanded ? 1 : 0,
                    }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    {additionalAppointments.map(
                      (appointment: any, index: any) => (
                        <React.Fragment key={index}>
                          {/* Render each additional appointment */}
                          <AppointmentDetails appointment={appointment} />

                          {/* Divider between additional appointments */}
                          {index < additionalAppointments.length - 1 && (
                            <div className="flex h-px w-full flex-none bg-neutral-100 my-4" />
                          )}
                        </React.Fragment>
                      )
                    )}
                  </motion.div>
                </Collapse>

                {/* Expand/Collapse Button at the bottom */}
                <div className="w-full text-center border-red-500">
                  <AsButton
                    className="flex w-full text-center text-neutral-500 pt-6 px-0"
                    text={isAppointmentsExpanded ? "Show Less" : "Show More"}
                    size="sm"
                    variant="link"
                    trailingIcon={
                      isAppointmentsExpanded
                        ? "FeatherChevronUp"
                        : "FeatherChevronDown"
                    }
                    onClick={() =>
                      setIsAppointmentsExpanded(!isAppointmentsExpanded)
                    }
                  />
                </div>
              </div>
            )}
          </div>

          {/* Reschedule and Cancel buttons */}
          <div className="flex w-full flex-col items-center gap-4 pt-6">
            <AsButton
              className="h-14 w-full flex-none"
              text="Reschedule appointment"
              size="lg"
              loader={false}
              onClick={() => onAppointmentReschedule()}
            />
            <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-900">
              or
            </span>
            <AsButton
              className="h-14 w-full flex-none"
              variant="secondary"
              text="Cancel appointment"
              size="lg"
              loader={cancelingAppointments}
              onClick={() => cancelAppointment()}
            />
          </div>
        </div>
      ) : (
        <div className="flex w-full items-center justify-center gap-2">
          <SubframeCore.Icon
            className="text-body font-body text-error-500"
            name="FeatherAlertTriangle"
          />
          <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
            Link is not valid. Please check your link and try again.
          </span>
        </div>
      )}
    </div>
  );
};

const AppointmentDetails = ({ appointment }: { appointment: any }) => (
  <div className="flex w-full flex-col items-center gap-4">
    <div className="flex w-full items-center gap-4">
      <Avatar
        src={appointment.services[0].employee.avatar}
        size={54}
        name={appointment.services[0].employee.name}
      />
      <div className="flex flex-col items-start justify-center gap-2">
        <span className="text-body-bold font-body-bold text-neutral-700">
          {appointment.services[0].employee.name}
        </span>
      </div>
    </div>
    <LineItems
      title="Service"
      value={appointment.services[0].title}
      additionalInfo=""
    />
    <LineItems
      title="Duration"
      value={getFormatedDuration(appointment.services[0].duration)}
    />
    <LineItems
      title="Time"
      value={format(
        preserveUtcTimeToLocal(appointment.startTime),
        "MMM do 'at' h:mm a"
      )}
      additionalInfo=""
    />
  </div>
);
