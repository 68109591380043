"use client";
/*
 * Documentation:
 * Mobile Nav bar — https://app.subframe.com/feb895689ba0/library?component=Mobile+Nav+bar_3adab306-a34b-4ca6-ad0b-bbae87d5cb65
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface NavIcon2Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const NavIcon2 = React.forwardRef<HTMLElement, NavIcon2Props>(function NavIcon2(
  { className, ...otherProps }: NavIcon2Props,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/b4ab962b flex w-12 flex-col items-center justify-center gap-1 pb-2",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full flex-col items-center justify-center gap-3">
        <div className="flex h-1 w-full flex-none flex-col items-center gap-2 rounded-[1px] bg-transparent group-focus-within/b4ab962b:bg-[#444449ff]" />
        <img
          className="h-8 w-8 flex-none object-cover"
          src="https://res.cloudinary.com/subframe/image/upload/v1728328852/uploads/3988/ef8kw9q7dttv2i9g9a4m.svg"
        />
      </div>
      <span className="font-['Inter'] text-[12px] font-[500] leading-[16px] text-neutral-500 group-focus-within/b4ab962b:text-neutral-700">
        Schedule
      </span>
    </div>
  );
});

interface MobileNavBarRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  className?: string;
}

const MobileNavBarRoot = React.forwardRef<HTMLElement, MobileNavBarRootProps>(
  function MobileNavBarRoot(
    {
      image,
      image2,
      image3,
      image4,
      className,
      ...otherProps
    }: MobileNavBarRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-112 items-center justify-between rounded-2xl bg-[#f5f3efb3] px-4 pb-2 shadow-pop-up-shadow backdrop-blur-sm",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-12 flex-none flex-col items-center justify-center gap-1 pb-2">
          <div className="flex w-full flex-col items-center justify-center gap-3">
            <div className="flex h-1 w-full flex-none flex-col items-center gap-2 rounded-[1px] bg-transparent" />
            <img
              className="h-8 w-8 flex-none object-cover"
              src="https://res.cloudinary.com/subframe/image/upload/v1728328852/uploads/3988/ef8kw9q7dttv2i9g9a4m.svg"
            />
          </div>
          <span className="font-['Inter'] text-[12px] font-[500] leading-[16px] text-neutral-500">
            Schedule
          </span>
        </div>
        <div className="flex w-12 flex-none flex-col items-center justify-center gap-1 pb-2">
          <div className="flex w-full flex-col items-center justify-center gap-3">
            <div className="flex h-1 w-full flex-none flex-col items-center gap-2 rounded-[1px] bg-transparent" />
            <img
              className="h-8 w-8 flex-none object-cover"
              src="https://res.cloudinary.com/subframe/image/upload/v1728329342/uploads/3988/hgcrreajovwwmgafailv.svg"
            />
          </div>
          <span className="font-['Inter'] text-[12px] font-[500] leading-[16px] text-neutral-500">
            Sales
          </span>
        </div>
        <div className="flex w-12 flex-none flex-col items-center justify-center gap-1 pb-2">
          <div className="flex w-full flex-col items-center justify-center gap-3">
            <div className="flex h-1 w-full flex-none flex-col items-center gap-2 rounded-[1px] bg-[#444449ff]" />
            <img
              className="h-8 w-8 flex-none object-cover"
              src="https://res.cloudinary.com/subframe/image/upload/v1728329356/uploads/3988/x99zatml9eyytnbb9wwp.svg"
            />
          </div>
          <span className="font-['Inter'] text-[12px] font-[500] leading-[16px] text-neutral-500">
            Clients
          </span>
        </div>
        <div className="flex w-12 flex-none flex-col items-center justify-center gap-1 pb-2">
          <div className="flex w-full flex-col items-center justify-center gap-3">
            <div className="flex h-1 w-full flex-none flex-col items-center gap-2 rounded-[1px] bg-transparent" />
            <img
              className="h-8 w-8 flex-none object-cover"
              src="https://res.cloudinary.com/subframe/image/upload/v1728329388/uploads/3988/kdx4dlzcteamhgnziwl9.svg"
            />
          </div>
          <span className="font-['Inter'] text-[12px] font-[500] leading-[16px] text-neutral-500">
            Menu
          </span>
        </div>
      </div>
    );
  }
);

export const MobileNavBar = Object.assign(MobileNavBarRoot, {
  NavIcon2,
});
