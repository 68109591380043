import { Path } from "app/path";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import { getAppointmentGroupByBookingToken } from "app/redux/appointmentGroupSlice";
import { createAppointment } from "app/redux/appointmentsSlice";
import { getCart, selectCart } from "app/redux/cartSlice";
import {
  payInPersonWithoutCreditCard,
  payWithCreditCard,
  preauthWithCreditCard,
} from "app/redux/paymentTransactionSlice";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { getFormValues } from "app/utils/getFormValues";
import { useQuery } from "app/utils/useQuery";
import { addMinutes, format } from "date-fns";
import { find, round } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export enum ModalTypes {
  INITIAL = "initial",
  CONFIRM_BOOKING = "confirmBooking",
}

export enum PaymentMethods {
  CREDIT_CARD = "creditCard",
  PAY_IN_PERSON = "payInPerson",
  PRE_AUTH = "preAuth",
}

export const tipOptions = [
  { label: "Tip later", value: 0 },
  { label: "18%", value: 0.18 },
  { label: "20%", value: 0.2 },
  { label: "22%", value: 0.22 },
  { label: "Other", value: 0 },
];

export const useOrder = () => {
  const { nameKey, shopKey } = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useSelector(selectAccount);
  const cart = useSelector(selectCart);
  const shop = find(account.shops, { key: shopKey });
  const [appointmentStartTimes, setAppointmentStartTimes] = useState<any>([]);
  const [tipsCents, setTipsCents] = useState(0);
  const [selectedTipOption, setSelectedTipOption] = useState<any>(
    tipOptions[0]
  );
  const [showOtherTipInput, setShowOtherTipInput] = useState(false);
  const [subtotalCents, setSubtotalCents] = useState(cart.subtotalCents);
  const [taxCents, setTaxCents] = useState(0);
  const [currentModal, setCurrentModal] = useState(ModalTypes.INITIAL);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods | null>(
    null
  );
  const [isCreditCardPaymentModalOpen, setIsCreditCardPaymentModalOpen] =
    useState(false);
  const [bookingPayload, setBookingPayload] = useState<any>(null);
  const [isProcessingCreditCardCharge, setIsProcessingCreditCardCharge] =
    useState(false);
  const [isPreAuthModalOpen, setIsPreAuthModalOpen] = useState(false);
  const [hasCreditCardInfoFailed, setHasCreditCardInfoFailed] = useState(false);
  const [appointmentGroupToReschedule, setAppointmentGroupToReschedule] =
    useState<any>(null);
  const groupedMerchantServices =
    Object.values(cart).length > 0
      ? Object.values(
          cart?.merchantServices.reduce((acc, item) => {
            const name = item.employee.name;
            if (!acc[name]) {
              acc[name] = { name, services: [], avatar: item.employee.avatar };
            }
            acc[name].services.push(item);
            return acc;
          }, {})
        )
      : [];

  const onEditBooking = () => {
    const timeUrl = generatePath(Path.BOOK_TIME, {
      nameKey,
      shopKey,
    } as any);

    navigate(`${timeUrl}?${query.toString()}`);
  };

  const navigateToOrderComplete = (paymentMethod: PaymentMethods) => {
    const orderCompleteUrl = generatePath(Path.ORDER_COMPLETE, {
      nameKey,
      shopKey,
    } as any);
    const cartParam = query.get("cart");

    switch (paymentMethod) {
      case PaymentMethods.CREDIT_CARD:
        return navigate(
          `${orderCompleteUrl}?cart=${cartParam}&payment=${PaymentMethods.CREDIT_CARD}`
        );
      case PaymentMethods.PAY_IN_PERSON:
        return navigate(
          `${orderCompleteUrl}?cart=${cartParam}&payment=${PaymentMethods.PAY_IN_PERSON}`
        );
      case PaymentMethods.PRE_AUTH:
        return navigate(
          `${orderCompleteUrl}?cart=${cartParam}&payment=${PaymentMethods.PRE_AUTH}`
        );
      default:
        return navigate(`${orderCompleteUrl}?cart=${cartParam}`);
    }
  };

  const makeAppointmentsWithNoPayment = async (appointmentData: any) => {
    setIsProcessingCreditCardCharge(true);
    try {
      await dispatch(
        payInPersonWithoutCreditCard({
          appointmentData,
          voidAppointmentGroupId: appointmentGroupToReschedule?.id,
        }) as any
      ).unwrap();
      await navigateToOrderComplete(PaymentMethods.PAY_IN_PERSON);
      setIsProcessingCreditCardCharge(false);
    } catch (error) {
      setIsProcessingCreditCardCharge(false);
      console.error(error);
    }
  };

  const onBook = async (event: any) => {
    event.preventDefault();
    if (!paymentMethod) {
      return toast.error("Please select a payment method");
    }
    const { firstName, lastName, phone, email } = getFormValues(event.target);
    setBookingPayload({ firstName, lastName, phone, email });
    if (paymentMethod === PaymentMethods.CREDIT_CARD) {
      setIsCreditCardPaymentModalOpen(true);
      return;
    }
    if (
      paymentMethod === PaymentMethods.PAY_IN_PERSON &&
      account.requireCreditCardForBooking
    ) {
      setIsPreAuthModalOpen(true);
      return;
    }

    try {
      const appointmentData = (await createAppointmentData({
        email,
        phone,
        name: `${firstName} ${lastName}`,
      })) as any;

      await makeAppointmentsWithNoPayment(appointmentData);
    } catch (error) {
      console.error(error);
    }
  };

  const onPayWithCash = async () => {
    try {
      const appointmentData = await createAppointmentData({});
      await makeAppointmentsWithNoPayment(appointmentData);
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialCreditCardInfo = async ({
    cardFormRef,
  }: {
    cardFormRef: any;
  }) => {
    try {
      setIsProcessingCreditCardCharge(true);
      const result = await cardFormRef.current.getNonceToken();
      if (!result) {
        setIsProcessingCreditCardCharge(false);
        setHasCreditCardInfoFailed(true);
        return;
      }

      return result;
    } catch (error) {
      setIsProcessingCreditCardCharge(false);
      console.error(error);
    }
  };

  const onPreAuthCreditCard = async (cardFormRef: any) => {
    try {
      const result = await getInitialCreditCardInfo({
        cardFormRef,
      });

      const appointmentData = await createAppointmentData({});
      await dispatch(
        preauthWithCreditCard({
          source: `nonce-${result.nonce}`,
          name: `${bookingPayload.firstName} ${bookingPayload.lastName}`,
          expiryMonth: result.expiryMonth,
          expiryYear: result.expiryYear,
          appointmentData,
          voidAppointmentGroupId: appointmentGroupToReschedule?.id,
        }) as any
      ).unwrap();
      setIsProcessingCreditCardCharge(false);
      setIsPreAuthModalOpen(false);
      navigateToOrderComplete(PaymentMethods.PRE_AUTH);
    } catch (error) {
      setIsProcessingCreditCardCharge(false);
      setIsPreAuthModalOpen(false);
      setHasCreditCardInfoFailed(true);
      console.error(error);
    }
  };

  const onPayWithCreditCard = async (cardFormRef: any) => {
    setIsProcessingCreditCardCharge(true);
    try {
      const result = await getInitialCreditCardInfo({
        cardFormRef,
      });

      // Create appointment data from the cart
      const appointmentData = await createAppointmentData({});

      // Total cents for the payment
      const totalCents = subtotalCents + tipsCents + taxCents;

      // Send the appointment_data and payment details to the backend
      await dispatch(
        payWithCreditCard({
          source: `nonce-${result.nonce}`, // Passing the nonce from the card form
          amount: Number((totalCents / 100).toFixed(2)), // Amount in dollars
          totalCents,
          subtotalCents,
          taxCents,
          tipsCents,
          paidOnline: true,
          name: `${bookingPayload.firstName} ${bookingPayload.lastName}`,
          expiryMonth: result.expiryMonth,
          expiryYear: result.expiryYear,
          tip: round(tipsCents / 100, 2),
          tax: round(taxCents / 100, 2),
          appointmentData, // Send the appointment data to the backend
          voidAppointmentGroupId: appointmentGroupToReschedule?.id,
        }) as any
      ).unwrap();

      setIsProcessingCreditCardCharge(false);
      navigateToOrderComplete(PaymentMethods.CREDIT_CARD);
    } catch (error) {
      setIsProcessingCreditCardCharge(false);
      setHasCreditCardInfoFailed(true);
      console.error(error);
    }
  };

  const createAppointmentData = async ({
    email,
    phone,
    name,
  }: {
    email?: string;
    phone?: string;
    name?: string;
  }) => {
    const appointmentData = [];
    let currentDateTime = preserveUtcTimeToLocal(cart.startTime!); // Initialize Date object

    for (const service of cart.merchantServices) {
      const duration = service.duration;
      const employeeId = service.employee.id;

      // Use currentDateTime to calculate the end time
      const serviceEndTime = addMinutes(currentDateTime, duration);

      // Construct appointment data object
      const appointment = {
        startTime: format(currentDateTime, "yyyy-MM-dd'T'HH:mm"), // Format only when needed
        endTime: format(serviceEndTime, "yyyy-MM-dd'T'HH:mm"), // Format when needed
        shopId: cart.shop.id,
        cartUid: query.get("cart"),
        status: "pending",
        companyId: cart.shop.account.id,
        email: email || bookingPayload?.email,
        phone: phone || bookingPayload?.phone,
        name: name || `${bookingPayload.firstName} ${bookingPayload.lastName}`,
        employeeId,
        serviceIds: [service.id],
        appointmentGroupId: null,
      };

      appointmentData.push(appointment);

      // Update currentDateTime for the next iteration
      currentDateTime = serviceEndTime;
    }

    return appointmentData; // Send the entire appointment data array to the backend
  };

  const init = async () => {
    const cartUid = query.get("cart");
    if (!cartUid) return;

    try {
      await dispatch(getAccount({ nameKey } as any) as any).unwrap();
    } catch (error) {
      console.error(error);
    }

    try {
      const fetchedCart = await dispatch(
        getCart({ uid: cartUid }) as any
      ).unwrap();
      setSubtotalCents(fetchedCart.subtotalCents);
      setTaxCents(
        (fetchedCart.shop.taxPercentage / 100) * fetchedCart.subtotalCents
      );
      const startDate = fetchedCart!.startTime.split("T")[0];
      let currentTime = fetchedCart!.startTime.split("T")[1];
      const times = [];
      for (const service of fetchedCart.merchantServices) {
        const duration = service.duration;

        // Preserve the UTC time and convert to local
        const startTime = `${startDate}T${currentTime}`;

        // Format the start time to avoid leading zeros (if desired)
        times.push(format(preserveUtcTimeToLocal(startTime), "h:mma")); // 'H' for no leading zero

        // Add the service duration to get the end time
        const serviceEndTime = addMinutes(startTime, duration);
        currentTime = format(serviceEndTime, "HH:mm"); // Maintain HH:mm for internal logic
      }
      setAppointmentStartTimes(times);
    } catch (error) {
      console.error(error);
    }

    if (query.get("appointmentGroupToReschedule")) {
      try {
        const appointmentGroup = await dispatch(
          getAppointmentGroupByBookingToken({
            bookingToken: query.get("appointmentGroupToReschedule"),
          } as any) as any
        ).unwrap();

        if (appointmentGroup) {
          setAppointmentGroupToReschedule(appointmentGroup);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        navigate("/");
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    account,
    cart,
    appointmentStartTimes,
    showOtherTipInput,
    tipsCents,
    setTipsCents,
    setShowOtherTipInput,
    selectedTipOption,
    setSelectedTipOption,
    subtotalCents,
    setSubtotalCents,
    currentModal,
    setCurrentModal,
    onEditBooking,
    paymentMethod,
    setPaymentMethod,
    onBook,
    isCreditCardPaymentModalOpen,
    setIsCreditCardPaymentModalOpen,
    onPayWithCreditCard,
    isProcessingCreditCardCharge,
    taxCents,
    isPreAuthModalOpen,
    setIsPreAuthModalOpen,
    setHasCreditCardInfoFailed,
    onPreAuthCreditCard,
    hasCreditCardInfoFailed,
    shop,
    onPayWithCash,
    bookingPayload,
    appointmentGroupToReschedule,
    groupedMerchantServices,
  };
};
