"use client";

// @subframe/sync-disable

import React, { useState } from "react";
import { Collapse } from "react-collapse";
import * as SubframeCore from "@subframe/core";
import { IconButton } from "./IconButton";
import { motion } from "framer-motion";

interface AccordionRootProps
  extends React.ComponentProps<typeof SubframeCore.Collapsible.Root> {
  text?: string;
  icon?: SubframeCore.IconName; // Default icon when closed
  openIcon?: SubframeCore.IconName; // Icon to use when opened
  className?: string;
}

export const CategoryAccordion = React.forwardRef<
  HTMLElement,
  AccordionRootProps
>(function AccordionRoot(
  {
    text,
    icon = "FeatherChevronDown", // Default icon for closed state
    openIcon = "FeatherChevronUp", // Icon for opened state
    children,
    defaultOpen = false,
    className,
    ...otherProps
  }: AccordionRootProps,
  ref
) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div
      ref={ref as any}
      className={SubframeCore.twClassNames("flex flex-col w-full", className)}
      {...otherProps}
    >
      <div
        className={SubframeCore.twClassNames(
          "flex w-full cursor-pointer items-center justify-between gap-2 p-4 border border-neutral-50 rounded-lg bg-white hover:bg-neutral-50",
          {
            "border-b-0 rounded-b-none": isOpen,
          }
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {text && (
          <span className="text-[18px] font-[500] leading-[28px] text-neutral-900">
            {text}
          </span>
        )}
        <IconButton
          variant="neutral-tertiary"
          size="small"
          icon={isOpen ? openIcon : icon} // Change icon based on open state
          loading={false}
        />
      </div>
      <Collapse isOpened={isOpen}>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className="flex flex-col items-start">{children}</div>
        </motion.div>
      </Collapse>
    </div>
  );
});
