"use client";
/*
 * Documentation:
 * AS Dropdown — https://app.subframe.com/feb895689ba0/library?component=AS+Dropdown_ea3c67c1-deb1-4526-aa3a-cf550a7cc3bc
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface AsDropdownItemProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const AsDropdownItem = React.forwardRef<HTMLElement, AsDropdownItemProps>(
  function AsDropdownItem(
    { className, ...otherProps }: AsDropdownItemProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/a9695e6f flex flex-col items-start gap-4 rounded-md px-2 py-2 active:bg-millet-500 focus-within:bg-millet-500",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <span className="text-body font-body text-neutral-700 group-active/a9695e6f:text-body-bold group-active/a9695e6f:font-body-bold group-focus-within/a9695e6f:text-body-bold group-focus-within/a9695e6f:font-body-bold">
          Label
        </span>
      </div>
    );
  }
);

interface AsDropdownRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const AsDropdownRoot = React.forwardRef<HTMLElement, AsDropdownRootProps>(
  function AsDropdownRoot(
    { children, className, ...otherProps }: AsDropdownRootProps,
    ref
  ) {
    return children ? (
      <div
        className={SubframeCore.twClassNames(
          "flex flex-col items-start gap-2 rounded-lg border border-solid border-neutral-50 bg-white px-2 py-2 shadow-lg",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    ) : null;
  }
);

export const AsDropdown = Object.assign(AsDropdownRoot, {
  AsDropdownItem,
});
