import { Path } from "app/path";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import { getAppointmentGroupByBookingToken } from "app/redux/appointmentGroupSlice";
import { selectCart, updateCart } from "app/redux/cartSlice";
import { getServiceCategories } from "app/redux/servicesSlice";
import { useQuery } from "app/utils/useQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";

export const getFormatedDuration = (duration: any) => {
  const hours = Math.floor(duration / 60); // Calculate the whole hours
  const minutes = duration % 60; // Calculate the remaining minutes

  if (hours > 0 && minutes > 0) {
    return `${hours} hr ${minutes} min${minutes > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} hr`;
  } else {
    return `${minutes} min`;
  }
};

export const useBookService = () => {
  const { nameKey, shopKey }: any = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const [isLoading, setIsLoading] = useState(false);
  const [shopProvidedServicesCategories, setShopProvidedServicesCategories] =
    useState([]);
  const account = useSelector(selectAccount);
  const [appointmentGroupToReschedule, setAppointmentGroupToReschedule] =
    useState<any>(null);

  const navigateToTime = () => {
    const timeUrl = generatePath(Path.BOOK_TIME, {
      nameKey,
      shopKey,
    } as any);
    navigate(`${timeUrl}?${query.toString()}`);
  };

  const navigateToStaff = () => {
    const serviceUrl = generatePath(Path.BOOK_STAFF, {
      nameKey,
      shopKey,
      serviceId: "null",
    } as any);

    navigate(`${serviceUrl}?${query.toString()}`);
  };

  const handleServiceClick = (service: any) => {
    const serviceProviderPath = generatePath(Path.BOOK_STAFF, {
      nameKey,
      shopKey,
      serviceId: service.id,
    });

    navigate(`${serviceProviderPath}?${query.toString()}`);
  };

  const onRemoveMerchantService = async (merchantService: any) => {
    const merchantServices = cart.merchantServices.filter(
      (ms) => ms.id !== merchantService.id
    );

    try {
      await dispatch(
        updateCart({
          merchantServiceIds: merchantServices.map((ms) => ms.id),
          shopId: cart.shop.id,
          uid: cart.uid,
        } as any) as any
      ).unwrap();
      const newSpsc = [...shopProvidedServicesCategories];
      newSpsc.forEach((category: any) => {
        category.services.forEach((service: any) => {
          if (service.id === merchantService.businessService.id) {
            service.selected = false;
          }
        });
      });

      setShopProvidedServicesCategories(newSpsc);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const fetchedServiceCategories = await dispatch(
          getServiceCategories(shopKey) as any
        ).unwrap();
        if (cart?.merchantServices?.length > 0) {
          const cartMerchantServiceIds = cart.merchantServices.map(
            (ms) => ms.businessService.id
          );
          fetchedServiceCategories.forEach((category: any) => {
            category.services.forEach((service: any) => {
              if (cartMerchantServiceIds.includes(service.id)) {
                service.selected = true;
              }
            });
          });
        }
        setShopProvidedServicesCategories(fetchedServiceCategories);
        await dispatch(getAccount({ nameKey }) as any);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }

      if (query.get("appointmentGroupToReschedule")) {
        try {
          const appointmentGroup = await dispatch(
            getAppointmentGroupByBookingToken({
              bookingToken: query.get("appointmentGroupToReschedule"),
            } as any) as any
          ).unwrap();

          if (appointmentGroup) {
            setAppointmentGroupToReschedule(appointmentGroup);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.error(error);
          navigate("/");
        }
      }
    })();
  }, []);

  return {
    isLoading,
    paramName: nameKey,
    shopProvidedServicesCategories,
    handleServiceClick,
    navigate,
    cart,
    onRemoveMerchantService,
    navigateToStaff,
    navigateToTime,
    account,
    appointmentGroupToReschedule,
  };
};
