"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Service card — https://app.subframe.com/feb895689ba0/library?component=Service+card_dee8fa73-5fd6-4a44-9c07-02c6da4d6526
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { truncate } from "lodash";

interface ServiceCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: React.ReactNode;
  text2?: React.ReactNode;
  text3?: React.ReactNode;
  text3ClassName?: string;
  variant?: "default";
  size?: "default" | "sm";
  className?: string;
}

const ServiceCardRoot = React.forwardRef<HTMLElement, ServiceCardRootProps>(
  function ServiceCardRoot(
    {
      text,
      text2,
      text3,
      variant = "default",
      size = "default",
      className,
      text3ClassName,
      ...otherProps
    }: ServiceCardRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/dee8fa73 flex w-full cursor-pointer items-start gap-2 border border-solid border-neutral-50 bg-white px-4 py-6 active:border active:border-solid active:border-neutral-700 active:bg-millet-300",
          { "px-4 py-4": size === "sm" },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex grow shrink-0 basis-0 flex-col items-start">
          <div className="flex w-full justify-between items-center">
            {text ? (
              <span className="text-body-bold font-body-bold text-neutral-700 group-hover/dee8fa73:text-green-400">
                {truncate(text as any, { length: 13, omission: ".." })}
              </span>
            ) : null}
            {text3 ? (
              <span
                className={`text-body-bold font-body-bold text-neutral-900 group-hover/dee8fa73:text-green-400 ${text3ClassName}`}
              >
                {text3}
              </span>
            ) : null}
          </div>
          {text2 ? (
            <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500 group-hover/dee8fa73:text-green-400">
              {text2}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
);

export const ServiceCard = ServiceCardRoot;
