"use client";
/*
 * Documentation:
 * ASIconButton — https://app.subframe.com/feb895689ba0/library?component=ASIconButton_83f0882b-d9cf-46fa-9b33-75d8d4c4387b
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface AsIconButtonRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?:
    | "default"
    | "secondary"
    | "brand-ghost"
    | "brand"
    | "destructive-secondary"
    | "destructive-ghost"
    | "neutral-secondary"
    | "ghost";
  leadingIcon?: SubframeCore.IconName;
  trailingIcon?: SubframeCore.IconName;
  text?: React.ReactNode;
  size?: "default" | "md" | "lg" | "sm" | "xs";
  disabled?: boolean;
  disabledSecondary?: boolean;
  className?: string;
}

const AsIconButtonRoot = React.forwardRef<HTMLElement, AsIconButtonRootProps>(
  function AsIconButtonRoot(
    {
      variant = "default",
      leadingIcon = "FeatherX",
      trailingIcon = null,
      text,
      size = "default",
      disabled = false,
      disabledSecondary = false,
      className,
      ...otherProps
    }: AsIconButtonRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/83f0882b flex h-10 cursor-pointer items-center justify-center gap-2 rounded-md border border-solid border-neutral-900 bg-neutral-900 px-3 py-2 hover:border hover:border-solid hover:border-neutral-700 hover:bg-neutral-700 active:border active:border-solid active:border-black active:bg-black",
          {
            "border border-solid border-neutral-400 bg-neutral-200 hover:border hover:border-solid hover:border-neutral-400 hover:bg-neutral-200 active:border active:border-solid active:border-neutral-400 active:bg-neutral-200":
              disabledSecondary,
            "border-none bg-neutral-200 hover:border-none hover:bg-neutral-200 active:border-none active:bg-neutral-200":
              disabled,
            "h-7 w-7 px-1 py-2": size === "xs",
            "h-8 w-8": size === "sm",
            "h-14 w-14 px-5 py-5": size === "lg",
            "h-12 w-12": size === "md",
            "rounded-md border-none bg-transparent hover:rounded-md hover:border-none hover:bg-neutral-100 active:border-none active:bg-neutral-200":
              variant === "ghost",
            "border border-solid border-neutral-100 bg-white hover:border hover:border-solid hover:border-neutral-100 hover:bg-neutral-50 active:border active:border-solid active:border-neutral-100 active:bg-white":
              variant === "neutral-secondary",
            "border-none bg-transparent hover:border-none hover:bg-error-100 active:border-none active:bg-error-200":
              variant === "destructive-ghost",
            "border-none bg-error-50 hover:border-none hover:bg-error-200 active:border-none active:bg-error-50":
              variant === "destructive-secondary",
            "border border-solid border-brand-500 bg-brand-500 hover:border hover:border-solid hover:border-brand-600 hover:bg-brand-200 active:border active:border-solid active:border-brand-500 active:bg-brand-500":
              variant === "brand",
            "rounded-md border-none bg-transparent hover:border-none hover:bg-brand-100 active:border-none active:bg-brand-300":
              variant === "brand-ghost",
            "bg-white hover:border hover:border-solid hover:border-brand-900 hover:bg-brand-100 active:bg-brand-300":
              variant === "secondary",
          },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            "text-body font-body text-white",
            {
              "text-neutral-900":
                variant === "ghost" ||
                variant === "neutral-secondary" ||
                variant === "brand" ||
                variant === "brand-ghost",
              "text-error-700":
                variant === "destructive-ghost" ||
                variant === "destructive-secondary",
              "text-neutral-900 group-hover/83f0882b:text-brand-900 group-active/83f0882b:text-neutral-900":
                variant === "secondary",
            }
          )}
          name={leadingIcon}
        />
      </div>
    );
  }
);

export const AsIconButton = AsIconButtonRoot;
