import {
  AsButton,
  AsIconButton,
  AsInput,
  Header,
  LineItems,
  PersonInfo,
  TipChip,
} from "app/subframe";
import * as React from "react";
import { ModalTypes, tipOptions, useOrder } from "./useOrder";
import { Loading } from "app/components/loading/loading";
import { format, parseISO } from "date-fns";
import { getFormatedDuration } from "app/book/bookService/useBookService";
import { round, sum } from "lodash";
import { InitialModal } from "./components/modals/initialModal";
import { ConfirmBookingModal } from "./components/modals/confirmBookingModal";
import { CreditCardPaymentModal } from "./components/modals/creditCardPaymentModal/creditCardPaymentModal";
import { PreAuthModal } from "./components/modals/preAuthModal/preAuthModal";
import { PaymentFailed } from "./components/paymentFailed/paymentFailed";
import classNames from "classnames";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";

export interface OrderProps {}

export const Order: React.FC<OrderProps> = ({}) => {
  const {
    account,
    cart,
    appointmentStartTimes,
    showOtherTipInput,
    setShowOtherTipInput,
    tipsCents,
    setTipsCents,
    selectedTipOption,
    setSelectedTipOption,
    subtotalCents,
    setSubtotalCents,
    currentModal,
    setCurrentModal,
    onEditBooking,
    paymentMethod,
    setPaymentMethod,
    onBook,
    isCreditCardPaymentModalOpen,
    setIsCreditCardPaymentModalOpen,
    isProcessingCreditCardCharge,
    onPayWithCreditCard,
    taxCents,
    isPreAuthModalOpen,
    setIsPreAuthModalOpen,
    setHasCreditCardInfoFailed,
    onPreAuthCreditCard,
    hasCreditCardInfoFailed,
    shop,
    onPayWithCash,
    bookingPayload,
    appointmentGroupToReschedule,
    groupedMerchantServices,
  } = useOrder();

  if (!cart.uid) {
    return (
      <div className="absolute left-1/2 top-1/3 -translate-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  const getModalToShow = () => {
    switch (currentModal) {
      case ModalTypes.INITIAL:
        return (
          <InitialModal
            setCurrentModal={setCurrentModal}
            tipsCents={tipsCents}
            subtotalCents={subtotalCents}
          />
        );
      case ModalTypes.CONFIRM_BOOKING:
        return (
          <>
            <ConfirmBookingModal
              setCurrentModal={setCurrentModal}
              tipsCents={tipsCents}
              taxCents={taxCents}
              subtotalCents={subtotalCents}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              onBook={onBook}
              bookingPayload={bookingPayload}
              appointmentGroupToReschedule={appointmentGroupToReschedule}
              isCreditCardPaymentModalOpen={isCreditCardPaymentModalOpen}
              isPreAuthModalOpen={isPreAuthModalOpen}
            />
            {isCreditCardPaymentModalOpen && (
              <div className="flex absolute h-full w-112 mobile:w-full z-10 top-0">
                <div className="bg-neutral-300/80 w-112 left-0 bottom-0 rounded-b-lg top-4 h-full mobile:w-full items-center" />
                <CreditCardPaymentModal
                  setIsCreditCardPaymentModalOpen={
                    setIsCreditCardPaymentModalOpen
                  }
                  isProcessingCreditCardCharge={isProcessingCreditCardCharge}
                  onPayWithCreditCard={onPayWithCreditCard}
                />
              </div>
            )}
            {isPreAuthModalOpen && (
              <div className="flex absolute h-full w-112 mobile:w-full z-10 top-0">
                <div className="bg-neutral-300/80 w-112 left-0 top-4 h-screen mobile:w-full items-center" />
                <PreAuthModal
                  setIsPreAuthModalOpen={setIsPreAuthModalOpen}
                  isProcessingCreditCardCharge={isProcessingCreditCardCharge}
                  onPreAuthCreditCard={onPreAuthCreditCard}
                />
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const modalToShow = getModalToShow();

  if (hasCreditCardInfoFailed) {
    return (
      <PaymentFailed
        account={account}
        shop={shop}
        paymentMethod={paymentMethod}
        setHasCreditCardInfoFailed={setHasCreditCardInfoFailed}
        setIsPreAuthModalOpen={setIsPreAuthModalOpen}
        onPayWithCash={onPayWithCash}
      />
    );
  }

  return (
    // <div
    //   className={classNames(
    //     "flex h-[95dvh] grow my-4 py-4 shrink-0 basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0",

    //     // "relative container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0 overscroll-none",
    //     {
    //       "mobile:mb-[180px]": currentModal === ModalTypes.INITIAL,
    //     }
    //   )}
    // >
    <div className="flex relative h-[95dvh] my-4 grow shrink-0  basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:my-0 mobile:rounded-none mobile:pb-4">
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account.logo}
        text={account.name}
        rescheduleFromStartTime={
          appointmentGroupToReschedule &&
          format(
            preserveUtcTimeToLocal(appointmentGroupToReschedule?.startTime),
            "MMM do 'at' h:mm a"
          )
        }
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0 ">
        <div className="flex w-full items-center">
          <AsButton
            variant="ghost"
            leadingIcon={"FeatherChevronLeft"}
            trailingIcon={null}
            text="Edit booking"
            size="sm"
            className="pl-0"
            onClick={() => onEditBooking()}
          />
        </div>
        <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4 scrollbar-hide">
          {groupedMerchantServices?.map((merchant: any, index: any) => {
            const day = format(
              preserveUtcTimeToLocal(cart.startTime!.split("T")[0]),
              "MMM d"
            );
            return (
              <>
                <PersonInfo
                  className="h-auto w-full flex-none"
                  name={merchant.name}
                />
                {merchant.services.map((ms: any, index: any, self: any) => (
                  <>
                    {index === 0 && (
                      <LineItems
                        title={`${day} at ${appointmentStartTimes[index]}`}
                        value={getFormatedDuration(
                          sum(self.map((s: any) => s.duration))
                        )}
                      />
                    )}
                    <LineItems
                      title={ms.title}
                      value={ms.price}
                      additionalInfo=""
                    />
                  </>
                ))}
              </>
            );
          })}

          <div className="flex w-full flex-col items-center gap-2 mobile:flex-col mobile:gap-2">
            <div className="flex w-full items-center gap-4">
              <span className="grow shrink-0 basis-0 text-body font-body text-neutral-700">
                Tips
              </span>

              <span className="text-[16px] font-[600] leading-[24px] text-neutral-900">
                {`$${round(tipsCents / 100, 2).toFixed(2)}`}
              </span>
            </div>
            <div className="flex w-full items-center gap-2 mobile:flex-row mobile:gap-2">
              {showOtherTipInput ? (
                <AsInput
                  type="number"
                  onChange={(e: any) => setTipsCents(e.target.value * 100)}
                  className="h-10 w-full"
                  leading="$"
                  textPosition="left"
                  placeholder={"0"}
                  trailingComponent={
                    <AsIconButton
                      className="m-1 w-8 h-8 flex-none"
                      variant="default"
                      leadingIcon="FeatherCheck"
                      text="Label"
                      size="default"
                      onClick={() => {
                        setShowOtherTipInput(false);
                      }}
                    />
                  }
                />
              ) : (
                tipOptions?.map((option, index) => {
                  return (
                    <TipChip
                      text={option.label}
                      active={selectedTipOption === option}
                      textClassName={classNames({
                        "!text-[13px]": index === 0,
                      })}
                      onClick={() => {
                        setSelectedTipOption(option);
                        setTipsCents(subtotalCents * option.value);
                        if (option.label === "Other") {
                          setShowOtherTipInput(true);
                        }
                      }}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-4 z-20 bg-transparent backdrop-blur-sm w-112 flex-none mobile:w-full mobile:flex-none flex flex-col items-center gap-2 shadow-pop-up-shadow rounded-lg mobile:bottom-0">
        {modalToShow}
      </div>
    </div>
  );
};
