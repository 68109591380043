import * as React from "react";
import { useBookStaff } from "./useBookStaff";
import { FadeInSlide } from "app/motions/fadeInSlide";
import classNames from "classnames";
import { Loading } from "app/components/loading/loading";
import {
  AsButton,
  BreadcrumbChip,
  Header,
  LineItems,
  PersonInfo,
  ProfileCard,
  ServiceCard,
} from "app/subframe";
import { Icon } from "@subframe/core";
import { getFormatedDuration } from "app/book/bookService/useBookService";
import { GenericSkeleton } from "app/components/skeleton/genericSkeleton";
import { truncate } from "lodash";
import { format } from "date-fns";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { MetaDescriptor } from "app/metaDescriptor/metaDescriptor";
import { Collapse } from "react-collapse";
import { motion } from "framer-motion";
import { avatar } from "@nextui-org/react";

export interface BookStaffProps {}

export const BookStaff: React.FC<BookStaffProps> = (props) => {
  const {} = props;
  const {
    serviceProviders,
    isLoading,
    getNextAvailabilityDay,
    account,
    selectedServiceProvider,
    onServiceProviderClick,
    cart,
    navigateToService,
    navigateToTime,
    appointmentGroupToReschedule,
    onRemoveMerchantService,
    additionalServicesWithProvider,
    onAdditionalServicePress,
    expandedAdditionalServiceProviderId,
    setExpandedAdditionalServiceProviderId,
    groupedMerchantServices,
  } = useBookStaff();

  const firstProviderWithServiceArray = [additionalServicesWithProvider?.[0]];
  const showServiceStaffPage = selectedServiceProvider && cart;

  if (isLoading) {
    return (
      <div className="my-4 mobile:pt-0 mobile:pb-0">
        <GenericSkeleton />
      </div>
    );
  }

  return (
    <div className="flex relative h-[95dvh] my-4 grow shrink-0  basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:my-0 mobile:rounded-none">
      <MetaDescriptor
        title={account.name}
        description={account.description}
        link={window.location.href}
      />
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account.logo}
        text={account.name}
        rescheduleFromStartTime={
          appointmentGroupToReschedule &&
          format(
            preserveUtcTimeToLocal(appointmentGroupToReschedule?.startTime),
            "MMM do 'at' h:mm a"
          )
        }
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 pb-10 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full items-center justify-between">
          <BreadcrumbChip text="Service" onClick={() => navigateToService()} />
          <Icon
            className="text-body font-body text-default-font"
            name="FeatherChevronRight"
          />
          <BreadcrumbChip text="Staff" selected={true} />
          <Icon
            className="text-body font-body text-default-font"
            name="FeatherChevronRight"
          />
          <BreadcrumbChip
            text="Time"
            onClick={() => {
              navigateToTime();
            }}
            disabled={
              !cart.merchantServices || cart.merchantServices.length === 0
            }
          />
        </div>
        {showServiceStaffPage ? (
          <>
            {/* basically show cart */}
            <div className="flex w-full flex-col items-end justify-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
              <span className="w-full text-[18px] font-[500] leading-[28px] text-neutral-900">
                Your services
              </span>
              {groupedMerchantServices?.map((merchant: any, index: any) => (
                <>
                  <PersonInfo
                    className="h-auto w-full flex-none"
                    name={merchant.name}
                    avatarImage={merchant.avatar}
                  />
                  {merchant.services.map((service: any) => (
                    <div className="flex flex-col w-full">
                      <div className="flex w-full items-center gap-4">
                        <div className="flex grow shrink-0 basis-0 items-center gap-2">
                          <span className="grow shrink-0 basis-0 font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-500">
                            {service.title}
                          </span>
                          <AsButton
                            className="h-8 w-16 flex-none"
                            variant="destructive-ghost"
                            leadingIcon={null}
                            trailingIcon={null}
                            text="Remove"
                            onClick={() => onRemoveMerchantService(service)}
                            size="sm"
                            loader={false}
                            disabled={false}
                            disabledSecondary={false}
                            shadow={false}
                          />
                        </div>
                      </div>
                      <LineItems
                        title="Duration"
                        value={getFormatedDuration(service.duration)}
                      />
                    </div>
                  ))}
                  <div
                    className={classNames(
                      "flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-200 mobile:bg-neutral-100",
                      {
                        hidden: index === groupedMerchantServices.length - 1,
                      }
                    )}
                  />
                </>
              ))}
            </div>
            {additionalServicesWithProvider?.length > 0 && (
              <div className="flex w-full flex-col items-start gap-2">
                {firstProviderWithServiceArray?.map(
                  ({ services, provider }: any, index: any) => {
                    const isExpanded =
                      expandedAdditionalServiceProviderId === provider.id;

                    return (
                      <div className="w-full">
                        <div
                          className="flex justify-between items-center pb-4 cursor-pointer"
                          onClick={() => {
                            if (isExpanded) {
                              setExpandedAdditionalServiceProviderId(null);
                            } else {
                              setExpandedAdditionalServiceProviderId(
                                provider.id
                              );
                            }
                          }}
                        >
                          <p>
                            Add another service by{" "}
                            <span className="font-semibold">
                              {provider.name}
                            </span>
                          </p>
                          <Icon
                            name={
                              isExpanded
                                ? "FeatherChevronUp"
                                : "FeatherChevronDown"
                            }
                          />
                        </div>
                        <Collapse key={index} isOpened={isExpanded}>
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{
                              height: isExpanded ? "auto" : 0,
                              opacity: isExpanded ? 1 : 0,
                            }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                          >
                            <div className="grid grid-cols-2 gap-4 w-full">
                              {services.map((service: any) => (
                                <ServiceCard
                                  className="rounded !p-2"
                                  text={service.title}
                                  text2={getFormatedDuration(service.duration)}
                                  text3={service.price}
                                  size="sm"
                                  text3ClassName="text-sm"
                                  onClick={() =>
                                    onAdditionalServicePress(service)
                                  }
                                />
                              ))}
                            </div>
                          </motion.div>
                        </Collapse>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </>
        ) : (
          <div className="grid grid-cols-2 w-full flex-wrap items-center gap-4 mobile:flex-row mobile:gap-4">
            {serviceProviders?.map((sp: any, index: number) => {
              const firstName = sp.name.split(" ")?.[0] || sp.name;
              const lastNameFirstLetter = sp.name.split(" ")?.[1]?.[0] || "";
              const nextAvailabilityDay = getNextAvailabilityDay(
                sp.availabilities
              );

              const [day, time] = nextAvailabilityDay.split(" at ");
              return (
                <FadeInSlide key={sp.id} direction="top">
                  <ProfileCard
                    className="h-auto grow shrink-0 basis-0"
                    image={sp.avatar}
                    text={truncate(`${firstName} ${lastNameFirstLetter}`, {
                      length: 16,
                      omission: ".",
                    })}
                    text2={`Available ${day}`}
                    text3={`At ${time}`}
                    onClick={() => {
                      onServiceProviderClick(sp);
                    }}
                  />
                </FadeInSlide>
              );
            })}
          </div>
        )}
      </div>
      {showServiceStaffPage && (
        <div
          className={classNames(
            "group/9af3f960 flex flex-col items-center justify-center gap-2 rounded-lg px-2 py-4 shadow-pop-up-shadow bg-[#ffffff80] backdrop-blur-sm sticky w-full bottom-0"
          )}
        >
          <div className="flex w-full flex-col items-center gap-4">
            <div className="flex w-full flex-col items-center gap-2">
              <span className="w-full text-body-bold font-body-bold text-neutral-900 text-center">
                Add a service by someone else?
              </span>
            </div>
            <div className="flex w-full items-center gap-4">
              <AsButton
                className="h-14 grow shrink-0 basis-0"
                variant="secondary"
                leadingIcon={"FeatherArrowLeft"}
                text="Yes"
                size="lg"
                onClick={() => navigateToService()}
              />
              <AsButton
                className="h-14 grow shrink-0 basis-0"
                trailingIcon={"FeatherArrowRight"}
                text="No, I'm done"
                size="lg"
                onClick={() => navigateToTime()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
